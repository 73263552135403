import { sbEditable, SbEditableBlok } from '@storyblok/storyblok-editable'
import * as React from 'react'
import { HotjarEvent, useTriggerHotjarSurvey } from '../lib/hooks/useTriggerHotjarSurvey';
import { DynamicComponent } from '../components/DynamicComponent'
import { ProductFeaturesHeader } from '../components/Product/ProductFeaturesHeader'
import { ProductNavigation } from '../components/Product/ProductNavigation'
import { Seo } from '../components/Seo'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ThemeName } from '../lib/constants'
import { useProduct } from '../lib/hooks/useProduct'
import { useStoryblok } from '../lib/storyblok'
import useDLPageViews from '../lib/hooks/tracking/useDLPageView'

const ProductFactsFeaturesTemplate = ({ pageContext }: { pageContext: SbEditableBlok }) => {
  let { story, content } =
    typeof window !== 'undefined' ? useStoryblok(pageContext, window.location) : useStoryblok(pageContext)

  const components = content.contentBlocks?.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  const product = useProduct({ id: content.product_reference })

  useDLPageViews(story)
  useTriggerHotjarSurvey(HotjarEvent.USER_PERSONA_SURVEY)

  return (
    <DefaultLayout
      subNavigation={<ProductNavigation blok={product?.content} />}
      theme={product?.content.colorTheme ? (`theme-${product?.content.colorTheme}` as ThemeName) : undefined}
    >
      <Seo
        title={pageContext?.field_pageTitle_string || product?.content?.productName}
        description={pageContext?.field_pageDescription_string || content?.headline}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      <ProductFeaturesHeader headline={content.headline} buttons={content.buttons} blok={product?.content} />
      <div {...sbEditable(story)}>{components}</div>
    </DefaultLayout>
  )
}

export default ProductFactsFeaturesTemplate
