import * as React from 'react'
import styled from 'styled-components'
import { IconName } from '../../lib/constants'
import { containerDefault } from '../../lib/styles/Container'
import { typoHeading1, typoParagraphIntro } from '../../lib/styles/Typography'
import { ProductBlok } from '../../types'
import CustomLink from '../CustomLink'
import { Button } from '../UI/Button'
import { IconList } from '../UI/IconList'
import { InfoList } from '../UI/InfoList'
import { useI18n } from '../../lib/context/i18n'

export interface ProductFeaturesHeaderProps {
  headline: string
  buttons?: []
  blok: ProductBlok
}

const Wrapper = styled.header`
  ${containerDefault};
  margin-block: 4rem;
  margin-inline: auto;
`

const Content = styled.div`
  flex: 1 1 auto;
  max-width: 36rem;
  width: 100%;
`

const ProductName = styled.div`
  ${typoParagraphIntro};
  color: var(--c-theme-1);
  margin-bottom: 0.5rem;
`

const ProductHeadline = styled.h1`
  ${typoHeading1}
`

const ListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`

const NavButtons = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 3rem;
`

export const ProductFeaturesHeader = ({ blok, headline, buttons }: ProductFeaturesHeaderProps) => {
  const operationSystems = blok?.operationsSystems
  const variants = blok?.productVariant

  const osIcons =
    operationSystems?.map(os => ({
      icon: os.content.icon as IconName,
      name: os.content.name,
    })) || []

  const variantsIcons =
    variants?.map(v => ({
      icon: v.content.icon as IconName,
      name: v.content.name,
    })) || []

  const { getTranslatedUrlFromLink } = useI18n()

  return (
    <Wrapper>
      <Content>
        <ProductName>{blok?.productName}</ProductName>
        <ProductHeadline>{headline}</ProductHeadline>
        <ListsWrapper>
          <IconList icons={[...osIcons, ...variantsIcons]} iconSize="1.2rem" />
          <InfoList text={[...(blok?.technologies || [])]} />
        </ListsWrapper>
        {buttons && (
          <NavButtons>
            <Button
              as={CustomLink}
              $variant="primary"
              to={
                buttons[0]?.product_code
                  ? `${getTranslatedUrlFromLink(buttons[0].link)}?product_code=${buttons[0].product_code}`
                  : getTranslatedUrlFromLink(buttons[0].link)
              }
            >
              {buttons[0].button_name}
            </Button>
            {buttons[1] && (
              <Button
                as={CustomLink}
                $variant="secondary"
                to={getTranslatedUrlFromLink(buttons[1].link)}
                iconRight={buttons[1].link_target && 'link-external'}
              >
                {buttons[1].button_name}
              </Button>
            )}
          </NavButtons>
        )}
      </Content>
    </Wrapper>
  )
}
